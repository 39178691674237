import React from 'react';
import styled from 'styled-components';
import layoutStyle from '../styles/layout.js';
import { colors, media } from '../styles/constants.js';

import Layout from '../components/layout.js';
import SEO from '../components/seo.js';
import SupplyChainNews from '../components/supplyChainNews.js';
import PropTypes from 'prop-types';
import ContactForm from '../components/contactForm.js';
import beerKegsImg from '../assets/images/beer_kegs.jpg';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:last-child {
    flex: 1 0 50%;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    h3 {
      line-height: 1.2;
      text-align: center;
      color: ${colors.blue};
      font-size: 1.5rem;
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function NewsPage({ location: { pathname } }) {
  return (
    <>
      <SEO
        title="Importance of Tracking RTIs"
        pathname={pathname}
        image={beerKegsImg}
        description="Explore the transformative impact of RTI tracking on modern supply chains in this blog post. Learn how Azitek's innovative solutions enhance efficiency, reduce costs, and promote sustainability by optimizing the management of Returnable Transport Items."
      />
      <Layout>
        <SupplyChainNews />
        <FormContainer>
          <ContactForm title="Contact Us" page="Use Cases" />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NewsPage;
