import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import beerKegsImg from '../assets/images/beer_kegs.jpg';
import filipe_silva from '../assets/images/filipe_silva.jpeg';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 3.75rem;

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle}

    h2 {
      text-align: center;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
        align-self: center;
      }

      @media (min-width: ${media.newsTextWidth}px) {
        width: 950px;
      }
    }
    h3 {
      font-size: 1.6rem;
    }
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const Signature = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  img {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: ${media.newsTextWidth}px) {
    width: 950px;
  }

  .image-midsection {
    @media (min-width: ${media.newsTextWidth}px) {
      display: flex;
      div {
        width: 100%;
      }
      img {
        width: 80%;
      }
    }
    @supports not (-moz-appearance: none) {
      @media (min-width: ${media.newsTextWidth}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  img {
    width: 100%;
    max-width: 950px;

    @media (min-width: ${media.navMaxLength}px) {
      width: 950px;
    }

    margin-bottom: 2rem;
    align-self: center;
  }

  ${TextContainer} {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1 1 100%;
    padding-bottom: 2rem;
    align-self: center;
  }

  .gatsby-image-wrapper {
    width: 50%;
    margin: 2rem auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: column-reverse;

    ${TextContainer} {
      font-size: 1.2rem;
      line-height: 1.8;
      flex: 1 0 55%;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2.5rem;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${TextContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function NewsPageComponent({
  title = 'The Impact of Tracking Returnable Transport Items in Modern Supply Chains',
  backgroundColor = colors.lightGrey
}) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <h2>{title}</h2>

        <HowItWorksContainer>
          <NewsWrapper>
            <TextContainer>
              <p>
                In the complicated web of global supply chains, the
                <strong> importance of sustainability</strong>, efficiency, and
                cost optimization has never been higher. As businesses navigate
                through difficult logistic operations and inventory management,
                the tracking of{' '}
                <strong>Returnable Transport Items (RTIs)</strong> emerges as a
                critical point. RTIs, such as pallets, IBCs, KLTs, RPCs, and all
                types of containers, are crucial for the transportation of goods
                across various parts of the supply chain. Their effective
                management can lead to significant improvements in{' '}
                <strong>
                  operational efficiency, cost savings, and environmental
                  sustainability
                </strong>
              </p>
              <p>
                The tracking of RTIs plays a crucial role in{' '}
                <strong>improving the visibility</strong> of goods as they move
                through the supply chain. By employing tracking solutions,
                businesses can gain <strong>real-time insights</strong> into the
                location, status, and condition of their RTIs. This level of
                transparency allows for the optimization of inventory levels,
                reduction of retention time, and more efficient use of
                resources. It attenuates the risks associated with lost, stolen,
                or <strong>underutilized assets</strong>, ensuring that RTIs are
                in the right place at the right time, thus{' '}
                <strong>
                  minimizing bottlenecks and boosting the flow of goods
                </strong>
              </p>
              <p>
                The economic implications of poorly managed RTIs are
                substantial. Businesses often face{' '}
                <strong>significant costs </strong>related to the loss, theft,
                or damage of these assets. Furthermore, the lack of visibility
                can lead to excessive purchasing or leasing of additional RTIs,
                tying up capital that could be better utilized elsewhere.
                Effective tracking solutions enable organizations to maintain{' '}
                <strong>optimal levels</strong> of RTIs,{' '}
                <strong>reducing unnecessary expenditures</strong> and improving
                the overall financial health of the supply chain.
              </p>
              <p>
                In today&#39;s eco-conscious world, the tracking of RTIs also
                supports sustainability initiatives. By maximizing the
                utilization and lifespan of these assets, businesses can
                significantly reduce their environmental footprint. This
                approach aligns with the principles of a circular economy, where
                resources are reused and recycled,{' '}
                <strong>minimizing waste</strong> and the demand for raw
                materials. The ability to track and efficiently manage RTIs is a
                step towards{' '}
                <strong>more sustainable supply chain practices</strong>,
                contributing to corporate social responsibility goals and
                regulatory compliance.
              </p>

              <h3>Azitek&#39;s Role in Revolutionizing RTI Tracking</h3>

              <p>
                As we delve into the future of supply chain management, the need
                for advanced RTI tracking solutions becomes even bigger. Azitek
                contributes to this transformation, offering an innovative
                solution for the effective{' '}
                <strong>monitoring and management</strong> of returnable assets.
                Our solution is designed to provide businesses the tools they
                need to{' '}
                <strong>
                  achieve greater visibility, efficiency, and sustainability
                </strong>{' '}
                in their supply chain operations.
              </p>

              <p>
                In conclusion, the tracking of RTIs is not just a logistical
                necessity; it is a strategic imperative that holds the key to
                unlocking new levels of efficiency, cost savings, and
                environmental care in supply chains. With Azitek&#39;s
                technology, businesses can navigate the complexities of RTI
                management with reliance, for a more{' '}
                <strong>connected, efficient, and sustainable future</strong>!
              </p>
              <p></p>
              <Signature>
                <img src={filipe_silva} />
                <p>
                  <strong>
                    Filipe Silva, Business Developer
                    <br /> Feb 2024
                  </strong>
                </p>
              </Signature>
            </TextContainer>
            <img
              className="origin-story-img"
              src={beerKegsImg}
              imgStyle={{ height: 'auto' }}
            />
          </NewsWrapper>
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

NewsPageComponent.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default NewsPageComponent;
